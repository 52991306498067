import { defineStore } from 'pinia'
// import { faker } from '@faker-js/faker'

// @ts-ignore
export const useUserStore = defineStore('User', {
  state: () => ({
    loggedIn: false,
    mfaConfirmed: false,
    profileImgSrc: '',
    profileName: '',
    profileEmail: '',
    // profileImgSrc: faker.image.avatar(),
    // profileName: faker.person.fullName(),
    // profileEmail: faker.internet.email()
  }),
  getters: {
  },
  actions: {
    signUp: async (email: string, password: string) => {
      if (process.client) {
        console.log('store signup')
        const { $amplify } = useNuxtApp()
        const user = await $amplify.signUp(email, password)
        console.log('user-store', user)
      }
    }

  },
  persist: {
    key: 'cash-studio_user',
    // cookieOptions: {
    //   maxAge: 14 * 60 * 60,
    //   // expires: 0
    // },
    debug: true,
  },
})
